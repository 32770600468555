import * as React from "react"
import Sidebar from "@components/vertical-sidebar/VerticalSidebar"
import NewDevelopmentsAdd from "@components/new-developments/Add"
import Seo from "@components/seo"
import "../../styles/index.scss"

const AdditionalParams = {
  endPointName: "new-developments.create"
}

const NewDevelopmentsAddPage = (props) => {
  return (
    <>
      <Seo title="Add New Developments" />
      <div className="main-wrapper">
        <Sidebar menuActive="new-developments"
          action="Add" />
        <NewDevelopmentsAdd
          menu="Add New Developments Details"
          parentPath="new-developments"
          pageType="Add"
          additionalParams={AdditionalParams} />
      </div>
    </>
  )
}

export default NewDevelopmentsAddPage;
